<template>
  <div class="w-100 p-l-30 p-r-30">
    <div class="contents-header">
      <p class="breadcrumb-menu">
        <router-link :to="{ name: 'TourAdminsIp', query: this.createSearchQuery()}"
                     class="text-decoration-underline cursor-pointer ashen-link">大会管理者/関係者管理
        </router-link>
        <i class="fas fa-angle-right form-control-color"></i>大会管理者/関係者新規登録
      </p>
    </div>
    <div class="card text-first">
      <div class="card-header bg-transparent">
        <img src="../assets/images/racket-gray.png"/>
        <span class="mx-3">{{ tournamentName }}</span>
      </div>
      <div class="card-body mt-4 mb-4 mx-auto">
        <div class="row">
          <div class="col-6">
            <label for="lastName">姓 名</label>
            <input id="lastName"
                   v-model="user.lastName"
                   class="form-control half-width-input mt-1"
                   placeholder=""
                   type="text"
            />
            <div v-show="errors['lastName']" class="form-error">{{ errors['lastName'] }}</div>
          </div>
          <div class="col-6">
            <label for="firstName"></label>
            <input id="firstName"
                   v-model="user.firstName"
                   class="form-control half-width-input mt-1"
                   placeholder=""
                   type="text"
            />
            <div v-show="errors['firstName']" class="form-error">{{ errors['firstName'] }}</div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col">
            <label for="exampleInputPassword1">メールアドレス</label>
            <input
                v-model="user.email"
                class="form-control"
                placeholder=""
                type="email"
            />
            <div v-show="errors['email']" class="form-error">{{ errors['email'] }}</div>
          </div>
        </div>
        <div class="row mt-4 dob-section">
          <label class="p-l-0">生年月日</label>
          <div class="d-inline-flex mt-1 p-l-0 p-r-0">
            <div class="input-group custom-select-year parent-block">
              <div class="child-block">年</div>
              <select
                  id="inputGroupSelectYear"
                  v-model="year"
                  class="form-control select_year"
              >
                <option v-for="year in years" :value="year">
                  {{ year }}
                </option>
              </select>
            </div>
            <div class="input-group custom-select-year parent-block">
              <div class="child-block">月</div>
              <select
                  id="inputGroupSelectMonth"
                  v-model="month"
                  class="form-control select_month"
              >
                <option selected value="01">01</option>
                <option value="02">02</option>
                <option value="03">03</option>
                <option value="04">04</option>
                <option value="05">05</option>
                <option value="06">06</option>
                <option value="07">07</option>
                <option value="08">08</option>
                <option value="09">09</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
              </select>
            </div>
            <div class="input-group custom-select-year parent-block m-r-0">
              <div class="child-block">日</div>
              <select
                  id="inputGroupSelectDate"
                  v-model="date"
                  class="form-control select_date"
              >
                <option selected value="01">01</option>
                <option value="02">02</option>
                <option value="03">03</option>
                <option value="04">04</option>
                <option value="05">05</option>
                <option value="06">06</option>
                <option value="07">07</option>
                <option value="08">08</option>
                <option value="09">09</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
                <option value="16">16</option>
                <option value="17">17</option>
                <option value="18">18</option>
                <option value="19">19</option>
                <option value="20">20</option>
                <option value="21">21</option>
                <option value="22">22</option>
                <option value="23">23</option>
                <option value="24">24</option>
                <option value="25">25</option>
                <option value="26">26</option>
                <option value="27">27</option>
                <option value="28">28</option>
                <option value="29">29</option>
                <option value="30">30</option>
                <option value="31">31</option>
              </select>
            </div>
          </div>
        </div>
        <div v-show="errors['birthday']" class="form-error">{{ errors['birthday'] }}</div>
        <div class="row mt-4">
          <div class="col">
            <label>ロール</label>
            <select
                v-model="user.userRoleId"
                class="form-control select-role mt-2 select-style form-select color-33"
            >
              <option
                  v-for="(role, index) in roles"
                  :key="'userRoleId-'+index"
                  :value="role.roleId"
              >
                {{ role.roleName }}
              </option>
            </select>
          </div>
        </div>

       <div class="row mt-4">
          <div class="col">
            <label for="exampleInputPassword1">申告事項（20文字以内、帯同者や所属団体等申告が必要な場合）</label>
            <input
                v-model="user.organization"
                class="form-control"
                placeholder=""
                type="text"
            />
            <div v-show="errors['organization']" id="err_organization" class="form-error">{{ errors['organization'] }}</div>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col form-check p-l-35">
            <input
                id="flexCheckDefault"
                v-model="user.pastAnswersRequired"
                :disabled="forcePastAnswersRequired"
                class="form-check-input"
                type="checkbox"
                value=""
            />
            <label class="form-check-label" for="flexCheckDefault">
              過去14日間における回答をさせる
            </label>
          </div>
        </div>
        <div class="row mt-4 d-flex justify-content-between">
          <div class="col">
            <button
                class="btn btn-light bg-white ashen-link text-decoration-none light border-0 w-100"
                @click="backToTourAdminSip"
            >
              キャンセル
            </button>
          </div>
          <div class="col">
            <button
                class="btn col btn-primary btn-outline-light font-weight-bold w-100"
                @click="createUser"
            >登録
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {apiConfig, app, datatable, gender, defaults} from "@/constants"
import {mapGetters} from "vuex"
import Common from "jsadminclient/common"

export default {
  name: "TourAdminsIpRegistration",
  props: {
    tournamentId: {type: Number, default: null}
  },
  data() {
    return {
      app: app,
      apiConfig: apiConfig,
      adminType: defaults.adminType,
      adminTypes: defaults.adminTypes,
      accountStatus: defaults.accountStatus,
      accountStatuses: defaults.accountStatuses,
      gender: gender,
      loading: true,
      columns: datatable.columns.touradminsip,
      forcePastAnswersRequired: true,
      tournamentList: {},
      tournamentName: '',
      ipUserId: "",
      ipUser: {},
      roles: {},
      roleId: defaults.roleId,
      user: {
        firstName: "",
        lastName: "",
        email: "",
        birthday: "",
        organization:"",
        pastAnswersRequired: false,
        userRoleId: 2,
      },
      year: "1990",
      month: "01",
      date: "01",
      reloadKey: 1,
      errors: []
    };
  },
  computed: {
    ...mapGetters(["config"]),
    years() {
      const year = new Date().getFullYear();
      return Array.from(
          {length: year - 1919},
          (value, index) => 1919 + (index + 1)
      )
    },
  },
  mounted() {
    this.apiConfig.headers["X-API-Token"] = this.config.token;
    this.getTournament()
    this.getRoles()
    this.getForcePastAnswersRequired()
  },
  methods: {
    backToTourAdminSip() {
      this.$router.push({name: "TourAdminsIp", query: this.createSearchQuery()});
    },
    async getForcePastAnswersRequired() {
      var api = new AdminApi.TournamentApi(this.getApiConfig())
      var result = await api.tournamentsListTournamentIdGetAsync(
          this.tournamentId
      )
      this.forcePastAnswersRequired = result.forcePastAnswersRequired
      this.user.pastAnswersRequired = result.forcePastAnswersRequired
    },
    async createUser() {
      try {
        this.errors = [];
        const apiConfig = new AdminApi.ApiConfig(
            this.apiConfig.basePath,
            this.apiConfig.headers
        );
        var api = new AdminApi.TournamentApi(apiConfig);
        this.user.birthday = this.year + "/" + this.month + "/" + this.date;
        var result = await api.tournamentsTournamentIdIpUsersPostAsync(
            this.tournamentId,
            this.user
        );
        Common.showToast("大会管理者/関係者が登録されました。")
        this.backToTourAdminSip();
      } catch (e) {
        this.loading = false;
        this.errors = Common.getErrorList(e.response, this.errors)
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    async getRoles() {
      try {
        const api = new AdminApi.SystemApi(this.getApiConfig());
        var result = await api.systemRolesGetAsync();
        this.roles = result.items;
      } catch (e) {
        this.roles = [];
        this.loading = false;
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    async getTournament() {
      try {
        const api = new AdminApi.TournamentApi(this.getApiConfig());
        var result = await api.tournamentsListTournamentIdGetAsync(
            this.tournamentId,
        );
        this.tournamentName = result.tournamentName
      } catch (e) {
        this.tournamentName = [];
        this.loading = false;
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    getApiConfig() {
      return new AdminApi.ApiConfig(
          this.apiConfig.basePath,
          this.apiConfig.headers
      );
    },
    createSearchQuery() {
      return Common.appendSearchQuery(this.$route.query)
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  font-size: 16px;
}

.delete {
  width: 160px;
  height: 44px;
}

.select-role.form-control {
  background: #daced1;
  color: #888888;
  background-image: url("../assets/images/down.png");
  background-position: calc(100% - 18px) 50%;
  background-repeat: no-repeat;
  background-size: 13px 13px;
}

.select-tournament.form-control {
  background: #f2ecf0;
  color: #888888;
  background-image: url("../assets/images/down.png");
  background-position: calc(100% - 18px) 50%;
  background-repeat: no-repeat;
  background-size: 13px 13px;
}

.form-check {
  padding-left: 33px;
}

.btn-file {
  position: relative;
  overflow: hidden;
  border: 1px solid #f2ecf0;
  height: 48px;
  line-height: 30px;
  border-radius: 0px;
}

.fa-upload {
  color: #f2ecf0;
}

.button-container {
  padding: 0px 190px;
}

.btn-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 999px;
  text-align: right;
  opacity: 0;
  outline: none;
  background: #fff;
  cursor: pointer;
  display: block;
}

.form-control {
  background: #f2ecf0;
  border: none;
}

::placeholder {
  color: #888888;
}

.custom-button {
  background: #c95d6c;
  color: #ffffff;
  border-radius: 4px;
  width: 160px;
}

.custom-button-outline.btn {
  background: #ffffff;
  color: #c95d6c;
  border: 1px solid #c95d6c;
  border-radius: 4px;
  width: 160px;
}

.card-container {
  padding: 7rem !important;
  padding-top: 2rem !important;
}

a {
  color: #333333;
}

.dob-section {
  .parent-block {
    .child-block {
      position: absolute;
      top: 0px;
      background: #f8f8f8;
      justify-content: center;
      align-items: center;
      display: flex;
      border: 1px solid #f0e7ed;
      border-radius: 0px 4px 4px 0px;
      height: 100%;
      max-height: 48px;
      width: 2.25rem;
      z-index: 9999;
      right: 0;
      color: #888888;
      border-top-right-radius: 4px !important;
      border-bottom-right-radius: 4px !important;
    }
  }

  .form-control {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
  }
}

.custom-select-year {
  width: 28%;
  margin-right: 23px;

  &:first-child {
    width: 40%;
  }

  &:last-child {
    width: 28%;
    margin-right: 0px !important;
  }
}

.filter-select,
.filter-search-form,
.btn-search {
  font-size: 13px;
  height: 32px !important;
  line-height: 13px;
}

.filter-search-form {
  border: 1px solid #dee2e6;
}

.btn-search {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.content {
  &.touradminsip {
    .custom-button {
      height: 32px;
      padding-top: 3px;

      img {
        margin-top: 2px;
      }
    }

    .custom-button-outline.btn {
      height: 32px;
      padding-top: 3px;

      img {
        margin-top: 2px;
      }
    }
  }
}
</style>
